import {createSlice} from "@reduxjs/toolkit";

const app = createSlice({
	name: 'app',
	initialState: {
		loading: true,
		isMobile: window.innerWidth < 768,
	},
	reducers: {
		ready(state, action) {
			state.loading = false;
		},
		windowResize(state, action) {
			state.isMobile = window.innerWidth < 768;
		},
	}
});

export default app;
