import React from "react";
import Color from "color";
import classNames from "classnames";

const LoadingIcon = ({className, color, style = {}, size}) => {
	if (size) {
		style.width = size;
		style.height = size;
	}
	if (color) {
		style.borderColor = Color(color).alpha(0.2).string();
		style.borderTopColor = color;
	}

	return (
		<div className={classNames('loading', className)} style={style}/>
	);
};

class Loading extends React.PureComponent {
	static ref = React.createRef();

	state = {
		visible: 0,
	};

	static show(...args) {
		Loading.ref.current._show(...args);
	}
	static hide(...args) {
		Loading.ref.current._hide(...args);
	}

	_show = () => {
		document.body.classList.add('loading-overlay-visible');
		this.setState({
			visible: this.state.visible+1,
		});
	};
	_hide = () => {
		document.body.classList.remove('loading-overlay-visible');
		this.setState({
			visible: Math.max(this.state.visible-1, 0),
		});
	};

	render() {
		const {visible} = this.state;

		if (visible <= 0) return null;

		return (
			<div className="loading-overlay">
				<LoadingIcon color={'#ffffff'}/>
			</div>
		);
	}
}

Loading.Icon = LoadingIcon;

export default Loading;

export {
	LoadingIcon
};
