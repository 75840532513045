import {createStore} from "@reduxjs/toolkit";
import {combineReducers} from "redux";

import authentication from "./authentication";
import app from "./app";
import menu from "./menu";

const reducers = combineReducers({
	authentication: authentication.reducer,
	app: app.reducer,
	menu: menu.reducer,
});

const store = createStore(reducers);

export default store;
