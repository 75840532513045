import React, {Suspense} from "react";
import {
	BrowserRouter,
	Switch,
	Route as DefaultRoute, Redirect,
} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import axios from "axios";
import _ from "lodash";

import authentication from "./redux/authentication";
import app from "./redux/app";
import {LoadingIcon} from "./components/Loading";

const AdminLogin = React.lazy(() => import("./views/admin/login"));
const AdminLogout = React.lazy(() => import("./views/admin/logout"));
const AdminClients = React.lazy(() => import("./views/admin/clients"));
const AdminClient = React.lazy(() => import("./views/admin/client/client"));
const AdminEvent = React.lazy(() => import("./views/admin/event/event"));
const AdminPlayer = React.lazy(() => import("./views/admin/player/player"));
const EventEmbed = React.lazy(() => import("./views/event/embed"));
const PlayerEmbed = React.lazy(() => import("./views/player/embed"));
const PlayerNetworkCheck = React.lazy(() => import("./views/check").then(module => ({default: module.PlayerTest})));
const NetworkCheck = React.lazy(() => import("./views/check"));

const SuspenseComponent = () => {
	return (
		<div className="position-fixed absolute-fill d-flex align-items-center justify-content-center">
			<LoadingIcon/>
		</div>
	);
};

const Route = ({roles, ...props}) => {
	const {user} = useSelector(({authentication}) => authentication);

	if (roles) {
		if (!user || (user && !_.includes(roles, user.role))) return (
			<Redirect
				to={{
					pathname: '/admin/login',
					state: {
						from: props.location,
					}
				}}
			/>
		);
	}

	return (
		<DefaultRoute {...props}/>
	);
};

const Router = () => {
	const dispatch = useDispatch();
	const {loading} = useSelector(({app}) => app);

	React.useEffect(() => {
		axios.get('/api/admin/auth/session')
			.then(({data}) => {
				dispatch(authentication.actions.login({user: data}));
			})
			.finally(() => {
				dispatch(app.actions.ready());
			});
	}, []);

	if (loading) return null;

	return (
		<Suspense fallback={<SuspenseComponent/>}>
			<BrowserRouter>
				<Switch>
					{/*region Authentication*/}
					<Route path="/admin/login" exact>
						<AdminLogin/>
					</Route>
					<Route path="/admin/logout" exact>
						<AdminLogout/>
					</Route>
					{/*endregion*/}

					<Route path="/admin/clients" roles={['admin', 'user']} exact>
						<AdminClients/>
					</Route>
					<Route path="/admin/clients/:client" roles={['admin', 'user']} exact>
						<AdminClient/>
					</Route>
					<Route path="/admin/clients/:client/events/:event" roles={['admin', 'user']} exact>
						<AdminEvent/>
					</Route>
					<Route path="/admin/clients/:client/events/:event/players/:player" roles={['admin', 'user']} exact>
						<AdminPlayer/>
					</Route>

					<Route path="/players/:client/:event/embed">
						<EventEmbed/>
					</Route>
					<Route path="/players/:client/:event/:player/embed">
						<PlayerEmbed/>
					</Route>
					<Route path="/players/:client/:event/:player/check">
						<PlayerNetworkCheck/>
					</Route>

					<Route path="/check">
						<NetworkCheck/>
					</Route>

					<Redirect from="/admin" to="/admin/clients" exact/>
					<Redirect from="/" to="/admin" exact/>
					<Route>
						NOT FOUND
					</Route>
				</Switch>
			</BrowserRouter>
		</Suspense>
	);
};

export default Router;
