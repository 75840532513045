import {createSlice} from "@reduxjs/toolkit";

const authentication = createSlice({
	name: 'authentication',
	initialState: {},
	reducers: {
		login(state, action) {
			const {user} = action.payload;
			state.user = user;
		},
		logout(state, action) {
			delete state.user
		}
	}
});

export default authentication;
