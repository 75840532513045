import React from "react";
import {Provider} from "react-redux";
import {library} from "@fortawesome/fontawesome-svg-core";
import {far} from "@fortawesome/free-regular-svg-icons";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {QueryParamProvider} from "use-query-params";
import axios from "axios";
import _ from "lodash";

import moment from "moment";
import momentDuration from "moment-duration-format";
import "moment/locale/it";

import "./scss/app.scss";

import Router from "./Router";
import store from "./redux";

import Confirm from "./components/Confirm";
import Loading from "./components/Loading";

_.mixin({
	flattenObject: function flattenObject(o, prefix = '', result = {}, keepNull = true) {
		if (_.isString(o) || _.isNumber(o) || _.isBoolean(o) || (keepNull && _.isNull(o))) {
			result[prefix] = o;
			return result;
		}

		if (_.isArray(o) || _.isPlainObject(o)) {
			for (let i in o) {
				let pref = prefix;
				if (_.isArray(o)) {
					pref = pref + `[${i}]`;
				} else {
					if (_.isEmpty(prefix)) {
						pref = i;
					} else {
						pref = prefix + '.' + i;
					}
				}
				flattenObject(o[i], pref, result, keepNull);
			}
			return result;
		}
		return result;
	},
})

momentDuration(moment);
moment.locale('it');

const RequestInterceptor = () => {
	React.useEffect(() => {
		axios.interceptors.response.use((response) => response, (error) => {
			return Promise.reject({
				error: error,
				data: _.get(error, 'response.data', {}),
			});
		});
	}, []);

	return null;
};

library.add(far, fas);

function App() {
	return (
		<Provider store={store}>
			<QueryParamProvider>
				<RequestInterceptor/>
				<Loading ref={Loading.ref}/>
				<Confirm ref={Confirm.ref}/>

				<Router/>
			</QueryParamProvider>
		</Provider>
	);
}

export default App;
