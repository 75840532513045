import React from "react";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from "reactstrap";
import _ from "lodash";

class Confirm extends React.Component {
	static ref = React.createRef();

	state = {
		dialogs: [],
	};

	static open(...params) {
		return Confirm.ref.current._open(...params);
	};

	_open = (options) => {
		if (_.isString(options)) {
			options = {
				title: options,
			};
		}

		const {dialogs} = this.state;

		const resolve = (cb) => {};
		const reject = (cb) => {};

		const dialog = {
			id: _.uniqueId(),
			open: true,
			then: resolve,
			catch: reject,
			options,
		};

		dialogs.push(dialog);

		this.setState({dialogs});

		return {
			then: function (cb) {
				dialog.then = cb;
			},
			catch: function (cb) {
				dialog.catch = cb;
			},
		}
	};

	_close = (id, success = false) => () => {
		const {dialogs} = this.state;

		this.setState({
			dialogs: _.map(dialogs, (dialog) => {
				if (dialog.id === id) {
					dialog.open = false;

					_.invoke(dialog, success ? 'then' : 'catch');
				}

				return dialog;
			}),
		});
	};

	_exit = (id) => () => {
		const {dialogs} = this.state;

		_.remove(dialogs, (dialog) => dialog.id === id);

		this.setState({dialogs});
	};

	render() {
		const {dialogs} = this.state;

		return _.map(dialogs, ({options, ...dialog}) => {
			return (
				<Modal
					key={dialog.id}
					isOpen={dialog.open}
					onClosed={this._exit(dialog.id)}
				>
					{!_.isEmpty(dialog.title) && (
						<ModalHeader>
							<h4>{dialog.title}</h4>
						</ModalHeader>
					)}
					<ModalBody className="py-4">
						<h4 className="mb-0 pb-0">{options.message}</h4>
					</ModalBody>
					<ModalFooter className="py-2">
						<Button size="sm" color="primary" onClick={this._close(dialog.id)}>No</Button>
						<Button size="sm" color="danger" onClick={this._close(dialog.id, true)}>Yes</Button>
					</ModalFooter>
				</Modal>
			)
		});
	}
}

export default Confirm;