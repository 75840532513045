import {createSlice} from "@reduxjs/toolkit";

const menu = createSlice({
	name: 'menu',
	initialState: {
		visible: false,
	},
	reducers: {
		open(state, action) {
			state.visible = true;
		},
		close(state, action) {
			state.visible = false;
		},
		toggle(state, action) {
			state.visible = !state.visible;
		},
	},
});

export default menu;
